@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.4/dist/web/static/pretendard-dynamic-subset.css");

@tailwind base; /* Preflight will be injected here */

@tailwind components;

@tailwind utilities;

@layer base {
    h1 {
      @apply text-4xl md:text-5xl xl:text-6xl font-bold leading-normal md:leading-normal xl:leading-normal text-gray-900;
    }
    h2 {
      @apply text-3xl xl:text-4xl font-bold leading-normal xl:leading-normal text-gray-900;
    }
    h3 {
      @apply text-2xl font-bold leading-normal xl:leading-normal text-gray-800;
    }
    h4 {
      @apply text-xl font-medium leading-relaxed xl:leading-relaxed text-gray-700;
    }
    p {
      @apply leading-normal text-gray-700;
    }
    a {
      @apply cursor-pointer;
    }
  }